import { Component } from '@angular/core';

import { SplashScreen } from '@capacitor/splash-screen';
import { BaseService } from "./services/base.service";
import { NavController } from "@ionic/angular";
import { Storage } from '@capacitor/storage';
import { Platform } from "@ionic/angular";
import { Capacitor } from '@capacitor/core';
import config from "../config";
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  constructor(
    public platform: Platform,
    public baseService: BaseService,
    public navController: NavController
  ) {

    // SplashScreen.show({
    //   autoHide: false
    // });

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.navigate();
    });
  }

  forceKiosk() {
    return config.PWA && Capacitor.getPlatform() == 'web';
  }

  navigate() {
    console.log('inside navigate');
    this.baseService.tokenFromStorage().then((token) => {
      console.log('resolved token from storage', token);
      SplashScreen.hide();
      if (token) {

        if(this.forceKiosk()) return this.navController.navigateRoot("kiosk-settings");
        
        Storage.get({ key: "kiosk" }).then((kiosk: any) => {

          if(kiosk && kiosk.value == 'true') return this.navController.navigateRoot("kiosk");
          else return this.navController.navigateRoot("tabs/tab1");
        });
        // return this.navController.navigateRoot("tabs/tab1");
        // return this.navController.navigateRoot("tabs/tab2");
        // return this.navController.navigateRoot("tabs/tab3");
        // return this.navController.navigateRoot("express-checkin");
        // return this.navController.navigateRoot("pickup-tag");
        
      } else {
        Storage.clear();
        console.log('cleared storage and moving to login');
        return this.navController.navigateRoot("login");
      }
    }, (err) => {
      console.log('err resolving tokenFromStorage', err);
    });
  }

  cancelScanning() {
    document.body.classList.remove("qrscanner");
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  }
}
