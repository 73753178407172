import { Injectable } from '@angular/core';
import { NavController } from "@ionic/angular";
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  token: any;

  constructor(public navController: NavController) { 
    this.tokenFromStorage();
  }

  getAuthHeaders() {
    return {
      Authorization: "Bearer " + this.token,
    };
  }

  async tokenFromStorage() {
    let token = await Storage.get({ key: "token" });

    this.token = token.value;

    return this.token;
  }
}
