import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'pickup-tag',
    loadChildren: () => import('./pickup-tag/pickup-tag.module').then( m => m.PickupTagPageModule)
  },
  {
    path: 'express-checkin',
    loadChildren: () => import('./express-checkin/express-checkin.module').then( m => m.ExpressCheckinPageModule)
  },
  {
    path: 'kiosk',
    loadChildren: () => import('./kiosk/kiosk.module').then( m => m.KioskPageModule)
  },
  {
    path: 'volunteers-checkin',
    loadChildren: () => import('./volunteers-checkin/volunteers-checkin.module').then( m => m.VolunteersCheckinPageModule)
  },
  {
    path: 'checkin-success',
    loadChildren: () => import('./checkin-success/checkin-success.module').then( m => m.CheckinSuccessPageModule)
  },
  {
    path: 'class-roster-single',
    loadChildren: () => import('./class-roster-single/class-roster-single.module').then( m => m.ClassRosterSinglePageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'family',
    loadChildren: () => import('./family/family.module').then( m => m.FamilyPageModule)
  },
  {
    path: 'family-member',
    loadChildren: () => import('./family-member/family-member.module').then( m => m.FamilyMemberPageModule)
  },
  {
    path: 'families',
    loadChildren: () => import('./families/families.module').then( m => m.FamiliesPageModule)
  },
  {
    path: 'checked-in-person',
    loadChildren: () => import('./checked-in-person/checked-in-person.module').then( m => m.CheckedInPersonPageModule)
  },
  {
    path: 'guardian-page',
    loadChildren: () => import('./guardian-page/guardian-page.module').then( m => m.GuardianPagePageModule)
  },
  {
    path: 'kiosk-settings',
    loadChildren: () => import('./kiosk-settings/kiosk-settings.module').then( m => m.KioskSettingsPageModule)
  },
  {
    path: 'printers',
    loadChildren: () => import('./printers/printers.module').then( m => m.PrintersPageModule)
  },
  {
    path: 'church-selection-modal',
    loadChildren: () => import('./church-selection-modal/church-selection-modal.module').then( m => m.ChurchSelectionModalPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'personal-account-setup-page',
    loadChildren: () => import('./personal-account-setup-page/personal-account-setup-page.module').then( m => m.PersonalAccountSetupPagePageModule)
  },
  {
    path: 'verification-page',
    loadChildren: () => import('./verification-page/verification-page.module').then( m => m.VerificationPagePageModule)
  },
  {
    path: 'setup-complete-page',
    loadChildren: () => import('./setup-complete-page/setup-complete-page.module').then( m => m.SetupCompletePagePageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
