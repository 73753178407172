import { environment } from "./environments/environment";

export const ENV = environment.name;

export const DEAFULT_API_VERSION = "v1";

export const API_URL = environment.api_url;

export const PWA = environment.pwa;

export default {
  ENV,
  API_URL,
  PWA
};
